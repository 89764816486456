import React, {useEffect, useState} from "react";
import {Col, List, Row, Typography} from "antd";

const {Text} = Typography;

interface Props {
}

const Home: React.FC<Props> = (props) => {
    const [clients, setClients] = useState<any>();
    useEffect(() => {
      const interval = setInterval(async () => {
        try {
          const response = await fetch("https://app.xairline.org/apis/ws/stats");
          const data = await response.json();
          setClients(data);
        } catch (error) {
          console.error("Error fetching stats:", error);
        }
      }, 5000); // 5000ms = 5 seconds

      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }, []);

    return ((
      <Row style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Col span={10}>
          <List
            header={<div>chatGPT Clients</div>}
            footer={<div>Total: {clients?.gpt_clients?.length}</div>}
            bordered
            dataSource={clients?.gpt_clients}
            renderItem={(item) => (
              <List.Item>
                <Typography.Title level={5}>
                  <>
                    {item}
                  </>
                </Typography.Title>
              </List.Item>
            )}
          />
        </Col>
        <Col span={10} offset={2}>
          <List
            header={<div>Multiple Players Clients</div>}
            footer={<div>Total: {clients?.mp_clients?.length}</div>}
            bordered
            dataSource={clients?.mp_clients}
            renderItem={(item) => (
              <List.Item>
                <Typography.Title level={5}>
                  <>
                    {item}
                  </>
                </Typography.Title>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    ))
      ;
  }
;

export default Home;
